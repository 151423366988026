/**
 * This middleware is used to capture and store URL query parameters string when a user visits a page.
 * These stored parameters are later used during the registration process and for tracking referral user activity.
 *
 * How it works:
 * 1. The middleware checks if the environment is production.
 * 2. It parses the query parameters from the URL.
 * 3. It identifies query parameters with null values.
 * 4. In null value queries it assigns to referrerAz the query parameter with a length of 32.
 *    If no such parameter exists it assigns the first null value query;
 * 5. The full query string (everything after the ? sign) is assigned to `pageQueryString`.
 * 6. If the user is not logged in, the `referrerAz` and `pageQueryString` are stored in localStorage.
 *
 */
import { useAuthStore } from '~/stores/auth';
import { LOCAL_STORAGE } from '~/constants/storedPropertiesNames';
import { APP_RUN_MODES } from '~/constants/general';

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) {
    return;
  }

  if (process.env.NODE_ENV === APP_RUN_MODES.production) {
    const { getUserData } = useAuthStore();
    const pageQueryParams = to.query;
    const pageQueryString = window.location.search.slice(1);
    const nullValueQueries = [];

    for (const [name, value] of Object.entries(pageQueryParams)) {
      if (!value) {
        nullValueQueries.push(name);
      }
    }
    if (!getUserData.loggedIn) {
      if (nullValueQueries.length) {
        const referrerAz = nullValueQueries.find((query) => query.length === 32) || nullValueQueries[0];

        localStorage.setItem(LOCAL_STORAGE.referrerAz, encodeURIComponent(referrerAz));
      }
      if (pageQueryString) {
        localStorage.setItem(LOCAL_STORAGE.pageQueryString, encodeURIComponent(pageQueryString));
      }
    }
  }
});
